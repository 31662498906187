import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

// Connects to data-controller="past-event"
export default class extends Controller {
  static targets = ["form", "combinedSearch", "eventStart", "venue", "resultsTable"];

  connect() {
    console.log("Stimulus past-event controller connected!");
    this.debounceTimeout = null;
    this.datePicker(); // Initialize Flatpickr when the controller connects

    // Ensure the combined search field gets focused on page load
    this.CombinedSearch();

    $('#venue_type').select2({
      minimumResultsForSearch: Infinity // Disable the search box
    });

    $('#venue_type').on('select2:select', () => {
      this.fetchResults(); // Trigger search when venue is selected
    });
  }

  // Initialize Flatpickr
  datePicker() {
    flatpickr(".date-input", {
      enableTime: false,
      locale: Japanese,
      wrap: true,
      allowInput: false,
      disableMobile: true,
      allowInvalidPreload: true,
      altInput: true,
      altFormat: "Y年m月d日",
      onChange: (selectedDates, dateStr, instance) => {
        this.search(); // Trigger search when date changes
      },
      onClose: (selectedDates, dateStr, instance) => {
        this.search();
      },
    });
  }

  // Trigger search with debounce
  search() {
    this.debounce(() => this.fetchResults(), 300);
  }

  // Fetch filtered results
  fetchResults() {
    const query = this.combinedSearchTarget?.value?.trim() || "";
    const eventStart = this.eventStartTarget?.value || "";
    const venue = this.venueTarget?.value || "";

    const params = new URLSearchParams();
    if (query) params.append("q[combined_search]", query);
    if (eventStart) params.append("q[event_start_eq]", eventStart);
    if (venue) params.append("q[venue_eq]", venue);

    fetch(`${this.formTarget.action}?${params.toString()}`, {
      headers: {
        Accept: "text/html",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch filtered results");
        return response.text();
      })
      .then((html) => {
        this.updateResults(html);
      })
      .catch((error) => console.error("Error fetching filtered results:", error));
  }

  // Update results dynamically
  updateResults(html) {
    console.log("Updating results...");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const tableContainer = doc.querySelector("tbody");
    const pagination = doc.querySelector("#page");
    const noDataText = doc.querySelector("#no-data-text");

    if (tableContainer) {
      this.resultsTableTarget.innerHTML = tableContainer.innerHTML;
    }

    if (pagination) {
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = pagination.innerHTML;
    }

    if (noDataText) {
      const customHTML = `
        <tr>
          <td colspan='7' class='text-center'>
            <div class="card py-3">
              <div class="card-body text-center py-10 my-5">
                該当のデータはありません。
              </div>
            </div>
          </td>
        </tr>
      `;
      // this.resultsTableTarget.innerHTML = customHTML;
      $('#nodata').html(customHTML);
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = "";
    }
  }

  CombinedSearch() {
    const combinedSearchField = document.getElementById("combined_search");
    if (combinedSearchField) {
      const valueLength = combinedSearchField.value.length;
      combinedSearchField.setSelectionRange(valueLength, valueLength);
    } else {
      console.error("Combined search field not found.");
    }
  }

  // Debounce function to delay actions
  debounce(callback, delay) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(callback, delay);
  }
}
