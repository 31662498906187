import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

let debounceTimeout;
export default class extends Controller {
  static targets = ['titleInput','publicDateInput', 'resultsTable','categorySelect'];
  connect() {
    console.log('Article Controller connected! 1');
    this.multipleSelect();
    this.date_picker();
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  date_picker() {
    flatpickr('.date-input', {
      enableTime: true,
      minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日  H時:i分',
      onChange: function (selectedDates, dateStr, instance) {
        
      },
    });
  
    flatpickr('.public_date_eq', {
      enableTime: false,
      locale: Japanese,
      wrap: true,
      allowInput: false,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      clearable: true,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y/m',
          altFormat: 'Y年m月',
          defaultDate: new Date(),
          theme: 'material_blue',
        }),
      ],
      onChange: (selectedDates) => {
        this.handleDateChange(selectedDates); 
      },
     
    });
  
    
    this.titleInputTarget.addEventListener('input', () => {
      this.handleSearchChange();
    });
    this.categorySelectTarget.addEventListener('change', () => {
      this.handleSearchChange();
    });

  }
  
  handleSearchChange() {
    const title = this.titleInputTarget.value;
    let date = this.publicDateInputTarget.value;
    const category = this.categorySelectTarget.value;

  if (date) {
    date = new Date(date); 
    if (isNaN(date)) {
      date = null; 
    }
  }
    this.filterResults(title, date,category); 
  }
  
  handleDateChange(selectedDates) {
    const title = this.titleInputTarget.value;
    const date = selectedDates.length > 0 ? selectedDates[0] : null;
    const category = this.categorySelectTarget.value;
    this.filterResults(title, date,category); 
  }
 
  filterResults(title, date,category) {
    const url = new URL(window.location.href);
  
    if (title) {
      url.searchParams.set("q[title_cont]", title);
    } else {
      url.searchParams.delete("q[title_cont]"); 
    }
  
    
    if (date) {
      url.searchParams.set("q[public_date_gteq]", `${date.getFullYear()}-${date.getMonth() + 1}-01`); 
      url.searchParams.set("q[public_date_lteq]", `${date.getFullYear()}-${date.getMonth() + 1}-${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}`); 
    } 

    if (category) {
      url.searchParams.set("q[categories_id_eq]", category);
    } else {
      url.searchParams.delete("q[categories_id_eq]");
    }

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        this.updateResults(html);
      })
      .catch((error) => console.error("Error fetching filtered results:", error));
  }
  
  // Update results dynamically
  updateResults(html) {
    console.log("Updating results...");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    const tableContainer = doc.querySelector("tbody");
    const pagination = doc.querySelector("#page");
    const noDataText = doc.querySelector("#no-data-text");
  
    if (tableContainer) {
      this.resultsTableTarget.innerHTML = tableContainer.innerHTML;
    }
  
    if (pagination) {
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = pagination.innerHTML;
    }
  
    if (noDataText) {
      const customHTML = `
             <tr><td colspan='7' class='text-center'>
                  <div class="card py-3">    
                       <div class="card-body text-center py-10 my-5">
                                    該当のデータはありません。
                       </div>
                  </div>
                </td></tr>
                `;
      $('#nodata').html(customHTML);
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = "";
      
    }
  }
  

  paginate(event) {
    event.preventDefault(); 
    const url = event.target.href;

    fetch(url, {
      headers: {
        Accept: "text/html", 
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch paginated results");
        }
        return response.text();
      })
      .then((html) => {
        this.updateResults(html); 
      })
      .catch((error) => console.error("Error fetching paginated results:", error));
  }
 

  showArticles(event) {
    const categoryId = event.target.dataset.categoriesCategoryIdValue;

    this.updateArticleFrame(categoryId);
  }

  updateArticleFrame(categoryId) {
    const articleListFrame = document.getElementById('article-list-frame');
    const articleOverviewFrame = document.getElementById('article-overview-frame');
    this.loadSpinner(articleListFrame);
    this.loadSpinner(articleOverviewFrame, true);

    fetch(`/student/articles/show_articles/${categoryId}`, {
      headers: { Accept: 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('article-list-frame').innerHTML = data.article_list_html;
        document.getElementById('article-overview-frame').innerHTML = data.article_overview_html;
      })
      .catch((error) => console.error('Error parsing JSON:', error));
  }

  updateArticleOverview(event) {
    const articleId = event.target.closest('.article-item').dataset.articleId;
    const turboFrame = document.getElementById('article-overview-frame');

    if (window.matchMedia('(max-width: 990px)').matches) {
      window.location.href = `/student/articles/${articleId}`;
      console.log(window.location.href);
      return;
    }

    this.loadSpinner(turboFrame, true);

    fetch(`/student/articles/${articleId}/show_overview`, {
      headers: { Accept: 'text/html' },
    })
      .then((response) => response.text())
      .then((html) => {
        turboFrame.innerHTML = html;
      })
      .catch((error) => console.error('Error parsing JSON:', error));
  }

  toggleFavorite(e) {
    const articleId = e.target.dataset.articleId;
    const isFav = e.target.dataset.favorite === 'true';

    fetch(`/student/articles/${articleId}/toggle_favorite`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify({ favorite: !isFav }),
    })
      .then((request) => request.json())
      .then((data) => {
        if (data.success) {
          if (e.target.classList.contains('bi-heart-fill')) {
            e.target.classList.remove('bi-heart-fill');
            e.target.classList.add('bi-heart');
          } else {
            e.target.classList.remove('bi-heart');
            e.target.classList.add('bi-heart-fill');
          }
        }
      })
      .catch((error) => console.error('Error parsing JSON:', error));
  }

  loadSpinner(element, overview = false) {
    element.innerHTML = `<div class="spinner-box d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="pulse-container ${overview ? 'white' : ''}">  
        <div class="pulse-bubble pulse-bubble-1"></div>
        <div class="pulse-bubble pulse-bubble-2"></div>
        <div class="pulse-bubble pulse-bubble-3"></div>
      </div>
    </div>`;
  }
}
