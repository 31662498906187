// app/javascript/controllers/chat_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['chat', 'chatMessage', 'time', 'messageForm', 'input', 'button'];

  connect() {
    console.log('Chat controller is connected successfully, change disability');
    this.delayPagination();
    this.showLatestMessage();
    this.changeTimeZone();
  }

  showLatestMessage() {
    const messages = this.chatMessageTargets;
    if (messages.length > 0) {
      const lastMsg = messages[messages.length - 1];
      lastMsg.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  delayPagination() {
    const pagination = document.getElementById('pagination-controls');
    setTimeout(() => {
      pagination.className = 'd-block';
    }, 3000);
  }

  changeTimeZone() {
    this.timeTargets.forEach((time) => {
      const date = new Date(time.dataset.timestamp);
      time.innerHTML = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
    });
  }

  validateInput() {
    this.buttonTarget.disabled = !this.isValidMessage();
  }

  handleKeydown(e) {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        this.inputTarget.value.concat('\n');
        return;
      }

      e.preventDefault();
      if (this.buttonTarget.disabled) return;

      if (this.isValidMessage()) {
        this.messageFormTarget.requestSubmit();
        this.buttonTarget.disabled = true;
      }
    }
  }

  isValidMessage() {
    return this.inputTarget.value.trim().length > 0;
  }

  // adjustHeight() {
  //   this.inputTarget.style.height = 'auto';
  //   this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
  // }

  clearForm() {
    this.messageFormTarget.reset();
    this.buttonTarget.disabled = true;
  }
}
