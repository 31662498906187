import { Controller } from "@hotwired/stimulus"
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';


// Connects to data-controller="matches"
export default class extends Controller {
  static targets = [ "resultsTable","DateInput", "matchCategoryFilter","gameType"];
  connect() {
    console.log("Matches controller connected.....")
    this.date_picker();
    $('#match_category').select2({
      minimumResultsForSearch: Infinity
    });
    $('#match_category').on('select2:select', function(){
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    $('#game_type').select2({
      minimumResultsForSearch: Infinity
    });
    $('#game_type').on('select2:select', function(){
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  date_picker() {
    flatpickr('.date-input', {
      enableTime: true,
      minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日  H時:i分',
      onChange: function (selectedDates, dateStr, instance) {
        
      },
    });
  
    flatpickr('.date_eq', {
      enableTime: false,
      locale: Japanese,
      wrap: true,
      allowInput: false,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      clearable: true,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y/m',
          altFormat: 'Y年m月',
          defaultDate: new Date(),
          theme: 'material_blue',
        }),
      ],
      onChange: (selectedDates) => {
        this.handleDateChange(selectedDates); 
      },
     
    });
  
  }

  filterByGameType() {
    const gameType =this.gameTypeTarget.value; 
    const category = this.matchCategoryFilterTarget.value;
   
    if (gameType === "1") {
      console.log($('#club_indi_match'))
      $('#club_indi_match').show();
      $('#club_match').hide();
      
    }
    if (gameType === "2") {
      $('#club_match').show();
      console.log($('#club_match'))
      $('#club_indi_match').hide();
      
    } 
    if (gameType === "" || gameType === null){
      $('#club_indi_match').show();
      $('#club_match').show();
    }
    
  let date = this.DateInputTarget.value ? new Date(this.DateInputTarget.value) : null; 

  // Call filterResults with current values
  this.filterResults(date, gameType, category);
  }

  // Filter by Category
  filterByMatchCategory() {
    
    const category = this.matchCategoryFilterTarget.value;
    const gameType = this.gameTypeTarget.value;
    console.log(category,gameType)
    let date = this.DateInputTarget.value ? new Date(this.DateInputTarget.value) : null; 
    this.filterResults(date, gameType, category);
  }


  handleDateChange(selectedDates) {
    const category = this.matchCategoryFilterTarget.value;
    const gameType = this.gameTypeTarget.value;
    const date = selectedDates.length > 0 ? selectedDates[0] : null;
    this.filterResults(date, gameType, category); 
  }
  
  filterResults(date, gameType, category) {
    const url = new URL(window.location.href);
    console.log(date, gameType, category)
    if (gameType) {
      url.searchParams.set("q[game_type]", gameType); 
    } else {
      url.searchParams.delete("q[game_type]"); 
    }
    if (category) {
      url.searchParams.set("q[match_category_eq]", category); 
    } else {
      url.searchParams.delete("q[match_category_eq]"); 
    }
  
    // Update the date filter
    if (date) {
      url.searchParams.set("q[date_gteq]", `${date.getFullYear()}-${date.getMonth() + 1}-01`); // Beginning of the month
      url.searchParams.set("q[date_lteq]", `${date.getFullYear()}-${date.getMonth() + 1}-${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}`); // End of the month
    } 
    
    // Fetch filtered results dynamically
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        this.updateResults(html, gameType);
      })
      .catch((error) => console.error("Error fetching filtered results:", error));
  }
  
  // Update results dynamically
  updateResults(html, gameType) {
    console.log("Updating results...");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    const teamTableContainer = doc.querySelector("tbody.club_match");
    const indiTableContainer = doc.querySelector("tbody.club_indi");
    const teampagination = doc.querySelector("#club_match_page");
    const indipagination = doc.querySelector("#indi_match_page");
  
    const indiTable = document.querySelector("tbody.club_indi");
    const teamTable = document.querySelector("tbody.club_match");
    const teampaginationDiv = document.querySelector("#club_match_page");
    const indipaginationDiv = document.querySelector("#indi_match_page");
  
    // Handle gameType filter
    if (gameType === "1") {
      // Update club_indi_match table
      if (indiTableContainer && indiTableContainer.innerHTML.trim() !== "") {
        indiTable.innerHTML = indiTableContainer.innerHTML;
        // Update pagination
        if (indipagination && indipaginationDiv) {
          indipaginationDiv.innerHTML = indipagination.innerHTML;
        }
      } else {
        indiTable.innerHTML = this.generateNoDataMessage();
        // Clear pagination
        if (indipaginationDiv) {
          indipaginationDiv.innerHTML = "";
        }
      }
      // Clear club_match table
      teamTable.innerHTML = "";
    } else if (gameType === "2") {
      // Update club_match table
      if (teamTableContainer && teamTableContainer.innerHTML.trim() !== "") {
        teamTable.innerHTML = teamTableContainer.innerHTML;
        // Update pagination
        if (teampagination && teampaginationDiv) {
          teampaginationDiv.innerHTML = teampagination.innerHTML;
        }
      } else {
        teamTable.innerHTML = this.generateNoDataMessage();
        // Clear pagination
        if (teampaginationDiv) {
          teampaginationDiv.innerHTML = "";
        }
      }
      // Clear club_indi table
      indiTable.innerHTML = "";
    } else {
     // No gameType filter, update both tables independently
    if (indiTableContainer && indiTableContainer.innerHTML.trim() !== "") {
      indiTable.innerHTML = indiTableContainer.innerHTML;
      if (indipagination && indipaginationDiv) {
        indipaginationDiv.innerHTML = indipagination.innerHTML;
      }
    } else {
      indiTable.innerHTML = this.generateNoDataMessage();
      if (indipaginationDiv) {
        indipaginationDiv.innerHTML = "";
      }
    }

    if (teamTableContainer && teamTableContainer.innerHTML.trim() !== "") {
      teamTable.innerHTML = teamTableContainer.innerHTML;
      if (teampagination && teampaginationDiv) {
        teampaginationDiv.innerHTML = teampagination.innerHTML;
      }
    } else {
      teamTable.innerHTML = this.generateNoDataMessage();
      if (teampaginationDiv) {
        teampaginationDiv.innerHTML = "";
      }
    }
  }
}
  // Helper method to generate the "No Data" message
  generateNoDataMessage() {
    return `
      <tr><td colspan="7" class="text-center">
        <div class="card py-3">    
          <div class="card-body text-center py-10 my-5">
            該当のデータはありません。
          </div>
        </div>
      </td></tr>
    `;
  }
  
  
}
