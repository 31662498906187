import consumer from './consumer';

(() => {
  if (!window.location.href.includes('student/')) return;

  const chat_room = document.getElementById('chat_room');
  const roomId = chat_room.dataset.roomId;

  consumer.subscriptions.create(
    { channel: 'ChatChannel', room_id: roomId },
    {
      connected() {
        console.log('Chat channel is connected');
      },

      disconnected() {
        console.log('Disconnected from ChatChannel!');
      },

      received(data) {
        console.log('Received a message');
        if (!window.location.pathname.includes('/chat')) return;
        const studentId = document.querySelector('[data-student-id]').dataset.studentId;
        const messages = document.getElementById('messages');
        handleMsgGroup(data, studentId);
        scrollToLastMsg(messages, data, studentId);
      },
    },
  );

  const handleMsgGroup = (msg, studentId) => {
    const dateGroups = document.querySelectorAll('.date-group');
    if (dateGroups.length === 0) return createNewMsgGroup(msg, studentId);

    const lastDateGroup = dateGroups[dateGroups.length - 1];
    const MsgGroups = lastDateGroup.querySelectorAll('.message-group');
    const lastMsgGroup = MsgGroups[MsgGroups.length - 1];
    const lastDate = lastMsgGroup.dataset.date;
    const format_lastDate = new Date(lastDate).toLocaleDateString();
    const format_today = new Date().toLocaleDateString();

    if (format_lastDate === format_today) {
      createMessage(lastMsgGroup, msg, studentId);
    } else {
      createNewMsgGroup(msg, studentId);
    }
  };

  const createNewMsgGroup = (msg, studentId) => {
    const message_list = document.getElementById('scroll-list');
    const dateGroup = createNewDateGroup(new Date());
    const msgGroup = dateGroup.querySelector('.message-group');

    createMessage(msgGroup, msg, studentId);
    message_list.append(dateGroup);
  };

  const createNewDateGroup = (date) => {
    const date_id = formatDate(date, 'YYYY-MM-DD');
    const jp_date = formatDate(date, 'YYYY年MM月DD日');

    const newDateGroup = document.createElement('div');
    newDateGroup.className = 'date-group';
    newDateGroup.id = `date-${date_id}`;
    newDateGroup.innerHTML = `
    <h5 class="text-center text-muted date-label">${jp_date}</h5>
    <div class="message-group" data-date="${date}">
    </div>
  `;

    return newDateGroup;
  };

  const createMessage = (parent, msg, studentId) => {
    const sender_id = msg.user._id == studentId ? studentId : msg.user._id;
    const member_name = msg.user._id == studentId ? '' : msg.user.name;
    const time_css = msg.user._id == studentId ? 'text-end' : '';
    const time = new Date(msg.createdAt).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    const new_msg = `
      <div class="chat msg-${sender_id} last my-4" data-chat-target="chatMessage" data-message-id="${msg._id}">
          <small class="text-muted ps-2 sender">${member_name}</small>
          <div class="message mt-1">
            <p class="m-0 msg-content">${msg.text}</p>
            <p class="${time_css} mt-2 mb-0 time-text" style="font-size: 0.78rem;" >${time}</p>
          </div>
      </div>
      `;

    parent.insertAdjacentHTML('beforeend', new_msg);
  };

  const scrollToLastMsg = (parent, msg, studentId) => {
    const sender_id = msg.user._id == studentId ? studentId : msg.user._id;

    const all_msg = parent.querySelectorAll(`.msg-${sender_id}`);
    const last_msg = all_msg[all_msg.length - 1];
    last_msg.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const formatDate = (date, date_format) => {
    const format_date = new Date(date);
    const moment = require('moment');
    return moment(format_date).format(date_format);
  };
})();
