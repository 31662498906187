import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="students"
export default class extends Controller {
  static targets = ['searchBox', 'resultsTable'];
  connect() {
    console.log("Student Controller connected...")
  }
  search() {
    const studentsearch = this.searchBoxTarget.value;
    // Use searchResults to perform the dynamic filtering
    this.searchResults(studentsearch);
  }
  
  searchResults(studentsearch) {
    const url = new URL(window.location.href);
  
    // Update the search query for name
    if (studentsearch) {
      url.searchParams.set("q[student_search]", studentsearch); 
    } else {
      url.searchParams.delete("q[student_search]"); 
    }
  
    // Fetch filtered results dynamically
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        this.updateResults(html);
      })
      .catch((error) => console.error("Error fetching filtered results:", error));
  }
    
    // Update results dynamically
    updateResults(html) {
      console.log("Updating results...");
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
    
      const tableContainer = doc.querySelector("tbody");
      const pagination = doc.querySelector("#page");
      const noDataText = doc.querySelector("#no-data-text");
    
      if (tableContainer) {
        this.resultsTableTarget.innerHTML = tableContainer.innerHTML;
      }
    
      if (pagination) {
        const paginationDiv = document.querySelector("#page");
        if (paginationDiv) paginationDiv.innerHTML = pagination.innerHTML;
      }
    
      if (noDataText) {
        const customHTML = `
               <tr><td colspan='7' class='text-center'>
                    <div class="card py-3">    
                         <div class="card-body text-center py-10 my-5">
                                      該当のデータはありません。
                         </div>
                    </div>
                  </td></tr>
                  `;
        $('#nodata').html(customHTML);
        
        const paginationDiv = document.querySelector("#page");
        if (paginationDiv) paginationDiv.innerHTML = "";
        
      }
    }
    
}
