import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

// Connects to data-controller="organizations"
let debounceTimeout;

export default class extends Controller {
  static targets = ["searchBox", "resultsTable", "createdAtGteq", "createdAtLteq"];

  connect() {
    console.log("Organizations controller connected!");
    this.datePicker(); 
  }

  // Initialize Flatpickr
  datePicker() {
    flatpickr(".date-input", {
      enableTime: false,
      // minDate: "today",
      locale: Japanese,
      wrap: true,
      allowInput: false,
      disableMobile: true,
      allowInvalidPreload: true,
      altInput: true,
      clearable: true,
      altFormat: "Y年m月d日",
     
      onChange: (selectedDates, dateStr, instance) => {
        this.handleDateChange();
      },
      
    });
  }

  // Handle combined search input
  search() {
    this.debounce(() => this.fetchResults(), 300);
  }

  // Handle date changes
  handleDateChange() {
    this.debounce(() => this.fetchResults(), 300);
  }

  // Fetch filtered results
  fetchResults() {
    const query = String(this.searchBoxTarget?.value || "").trim();
    const createdAtGteq = this.createdAtGteqTarget?.value || "";
    const createdAtLteq = this.createdAtLteqTarget?.value || "";

    const params = new URLSearchParams();
    if (query) params.append("q[combined_search]", query);
    if (createdAtGteq) params.append("q[created_at_gteq]", createdAtGteq);
    if (createdAtLteq) params.append("q[created_at_lteq]", createdAtLteq);

    fetch(`/admin/organizations?${params.toString()}`, {
      headers: {
        Accept: "text/html",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch filtered results");
        return response.text();
      })
      .then((html) => {
        this.updateResults(html);
      })
      .catch((error) => console.error("Error fetching filtered results:", error));
  }

  // Update results dynamically
  updateResults(html) {
    console.log("Updating results...");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const tableContainer = doc.querySelector("tbody");
    const pagination = doc.querySelector("#page");
    const noDataText = doc.querySelector("#no-data-text");

    if (tableContainer) {
      this.resultsTableTarget.innerHTML = tableContainer.innerHTML;
    }

    if (pagination) {
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = pagination.innerHTML;
    }

    if (noDataText) {
      const customHTML = `
             <tr><td colspan='7' class='text-center'>
                  <div class="card py-3">    
                       <div class="card-body text-center py-10 my-5">
                                    該当のデータはありません。
                       </div>
                  </div>
                </td></tr>
                `;
      $('#nodata').html(customHTML);
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = "";
      
    }
  }

  debounce(callback, delay) {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(callback, delay);
  }
}
