import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="line-progress-bar"
export default class extends Controller {
  static targets = ["progress"];

  connect() {
    // Get the progress value passed from the view
    const progress = parseFloat(this.data.get("progress"));
    this.updateProgress(progress);
  }

  updateProgress(progress) {
    // Calculate the width of the progress bar based on the percentage
    const width = progress * 2; // Max width is 200px, so 100% = 200px, 50% = 100px, etc.
    // Update the width of the progress bar
    this.progressTarget.setAttribute("width", width);
  }
}
