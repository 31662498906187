import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ['pagination', 'message'];

  connect() {
    console.log('Infinite scroll controller connected.');
    this.initializeObserver();
  }

  initializeObserver() {
    if (this.hasPaginationTarget) {
      this.observePaginationTarget();
    }
  }

  paginationTargetConnected() {
    console.log('Pagination target connected.');
    this.initializeObserver();
  }

  observePaginationTarget() {
    if (!this.hasPaginationTarget) return;

    if (this.observer) this.observer.disconnect(); // Disconnect any existing observer

    this.observer = new IntersectionObserver(this.loadMore.bind(this), {
      root: null,
      threshold: 0.1,
    });

    this.observer.observe(this.paginationTarget);
  }

  async loadMore(entries) {
    const entry = entries[0];
    console.log('is intersecting', entry.isIntersecting);
    if (entry.isIntersecting) {
      const nextPage = this.paginationTarget.dataset.nextPage;
      if (!nextPage) return;

      const url = new URL(window.location.href);
      url.searchParams.set('page', nextPage);

      const response = await fetch(url, {
        headers: { Accept: 'text/vnd.turbo-stream.html' },
      });

      if (response.ok) {
        const turboStream = await response.text();
        if (this.hasMessageTarget) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(turboStream, 'text/html');

          doc.querySelectorAll('template').forEach((template) => {
            const parent = template.parentElement;
            const action = parent.getAttribute('action');
            const target = parent.getAttribute('target');

            if (action === 'replace' && target === 'pagination-controls') {
              const newPagination = template.content.firstElementChild;
              const paginationTarget = document.querySelector(`#${target}`);
              if (paginationTarget) {
                // if (newPagination === null) {
                //   paginationTarget.remove();
                //   return;
                // }
                paginationTarget.replaceWith(newPagination);
              }
            } else if (action === 'prepend' && target === 'scroll-list') {
              const tempContent = template.content;
              const newMessages = tempContent.querySelectorAll('.message-group');

              const existingMessageIds = new Set(
                Array.from(document.querySelectorAll('.chat')).map((msg) => msg.dataset.messageId),
              );

              Array.from(newMessages).forEach((message) => {
                const messageId = message.querySelector('.chat').dataset.messageId;
                if (!existingMessageIds.has(messageId)) {
                  this.groupAndInsertMessage(message);
                }
              });
            }
          });
        } else {
          document.documentElement.insertAdjacentHTML('beforeend', turboStream);
        }

        this.observePaginationTarget();
      }
    }
  }

  groupAndInsertMessage(newMessage) {
    const timeTexts = newMessage.querySelectorAll('.time-text');
    timeTexts.forEach((timeText) => {
      this.changeTimeZone(timeText.dataset.timestamp, timeText);
    });

    const dateGroupId = newMessage.dataset.date;
    let existingGroup = document.querySelector(`#date-${dateGroupId}`);

    if (existingGroup) {
      existingGroup.querySelector('.date-label').remove();
      existingGroup.prepend(newMessage);

      const dateLabel = this.createDateLabel(dateGroupId);
      existingGroup.prepend(dateLabel);
    } else {
      existingGroup = document.createElement('div');
      existingGroup.id = `date-${dateGroupId}`;
      existingGroup.className = 'date-group';

      const dateLabel = this.createDateLabel(dateGroupId);
      existingGroup.append(dateLabel);
      existingGroup.append(newMessage);

      document.querySelector('#scroll-list').prepend(existingGroup);
    }
  }

  createDateLabel(date) {
    const date_element = document.createElement('h5');
    date_element.classList = 'text-center text-muted date-label';
    date_element.innerHTML = this.changeDate(date);
    return date_element;
  }

  changeTimeZone(time, element) {
    const date = new Date(time);
    element.innerHTML = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  changeDate(date) {
    const format_date = new Date(date);
    const moment = require('moment');
    return moment(format_date).format('YYYY年MM月DD日');
    // return new Intl.DateTimeFormat('ja-JP', {
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric',
    // }).format(format_date);
  }
}
