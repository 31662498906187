import consumer from './consumer';

document.addEventListener('DOMContentLoaded', () => {
  console.log('Unread messages channel.js is loaded');

  // Getting Chat Member ID from the DOM
  const chatMemberIdElement = document.querySelector('[data-chat-member-id]');
  if (!chatMemberIdElement) return;
  const chatMemberId = chatMemberIdElement.dataset.chatMemberId;

  // Getting Chat Link and Unread Messages Badge from the DOM
  const chatLink = document.querySelector('.chat-badge.nav-link.p-5');
  let unreadMessagesBadge = document.querySelector('.nav-link .badge');

  //Get the server value from the rendered HTML if exists
  if (unreadMessagesBadge) {
    const serverUnreadMessagesCount = unreadMessagesBadge.textContent;
    console.log('Server provided unread messages count:', serverUnreadMessagesCount);

    sessionStorage.setItem('unread_messages_count', serverUnreadMessagesCount);
  } else {
    console.log('No Badge found, checking if need to initialize the session storage');
    if (!sessionStorage.getItem('unread_messages_count')) {
      sessionStorage.setItem('unread_messages_count', '0');
    }
  }

  // Getting Unread Messages Count from Session Storage
  const unreadMessagesCount = sessionStorage.getItem('unread_messages_count') || '0';
  console.log('Unread Messages Count from Session:', unreadMessagesCount);

  // Setting Unread Messages Count to Unread Badge
  if (unreadMessagesBadge) {
    unreadMessagesBadge.textContent = unreadMessagesCount;
  } else if (chatLink && unreadMessagesCount !== '0') {
    chatLink.insertAdjacentHTML(
      'beforeend',
      `<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">${unreadMessagesCount}</span>`
    );
    console.log('Added notification badge to chat link from sessionStorage');
    unreadMessagesBadge = document.querySelector('.nav-link .badge');
  }

  // Click the chat link to remove the notification badge and set sessionStorage to 0
  if (chatLink) {
    chatLink.addEventListener('click', () => {
      console.log('Chat icon clicked, resetting unread messages count to 0');
      sessionStorage.setItem('unread_messages_count', '0');

      if (unreadMessagesBadge) {
        unreadMessagesBadge.remove();
      }
    });
  }

  consumer.subscriptions.create(
    { channel: 'UnreadMessagesChannel', chat_member_id: chatMemberId },
    {
      connected() {
        console.log('UnreadMessagesChannel is connected successfully');
        // Ask for initial unread messages count when connecting
        this.perform('request_initial_count');
      },

      disconnected() {
        console.log('UnreadMessagesChannel is disconnected');
      },

      received(data) {
        //console.log('Received unread messages count:', data);
        console.log('Received unread messages count:', data.unread_messages_count);

        // Checking if the user is on the chat page
        const isOnChatPage = window.location.pathname.includes('/chats');
        console.log('Is User on Chat Page:', isOnChatPage);

        if (isOnChatPage) {
          // Resetting the Unread Messages Count to 0 in Session Storage
          console.log('User is on the chat page, setting unread messages count to 0');
          sessionStorage.setItem('unread_messages_count', '0');
          
          // Removing Unread Badge from Chat Link
          if (unreadMessagesBadge) {
            unreadMessagesBadge.remove();
          }

          // Updating messages as Seen in the backend
          fetch('/student/chats/mark_messages_as_seen', {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
            },
          })
            .then((response) => {
              if (response.ok) {
                console.log('Backend response:', response);
                return response.json();
              } 
            }) 
            .then((data) => {console.log("Backend Response data:", data)})
            .catch((error) => { 
              console.error('Error:', error);
            });
        } else {
          // Storing Unread Count in Session Storage
          console.log('Storing unread messages count in session storage:', data.unread_messages_count);
          sessionStorage.setItem('unread_messages_count', data.unread_messages_count);

          // Showing the Unread Badge
          if (unreadMessagesBadge) {
            unreadMessagesBadge.textContent = data.unread_messages_count;
          } else if (chatLink) {
            chatLink.insertAdjacentHTML(
              'beforeend',
              `<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">${data.unread_messages_count}</span>`
            );
            console.log('Added notification badge to chat link');
          }
        }
      },
    }
  );
});
