import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question-forms"
export default class extends Controller {
  static targets = ["searchTitle", "resultsTable", "categoryFilter"];

  connect() {
    console.log('Question Controller connected!, Html to text change');
    $('#question_category_id_eq').select2({
      minimumResultsForSearch: Infinity
    });
    $('#question_category_id_eq').on('select2:select', function(){
      let event = new Event('change', { bubbles: true }) 
      this.dispatchEvent(event);
    });
  }
  // Search by Title
  searchByTitle() {
    console.log("search");
    const title = this.searchTitleTarget.value;
    const category = this.categoryFilterTarget.value;

    this.filterResults(title, category);
  }

  // Filter by Category
  filterByCategory() {
    const category = this.categoryFilterTarget.value;
    const title = this.searchTitleTarget.value;

    this.filterResults(title, category);
  }

  filterResults(title, category) {
    const url = new URL(window.location.href);
  
    // Update the search query for title
    if (title) {
      url.searchParams.set("q[title_cont]", title); 
    } else {
      url.searchParams.delete("q[title_cont]"); 
    }
    if (category) {
      url.searchParams.set("q[question_category_id_eq]", category); 
    } else {
      url.searchParams.delete("q[question_category_id_eq]"); 
    }

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        this.updateResults(html);
      })
      .catch((error) => console.error("Error fetching filtered results:", error));
  }

  // Update results dynamically
  updateResults(html) {
    console.log("Updating results...");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    const tableContainer = doc.querySelector("tbody");
    const pagination = doc.querySelector("#page");
    const noDataText = doc.querySelector("#no-data-text");
  
    if (tableContainer) {
      this.resultsTableTarget.innerHTML = tableContainer.innerHTML;
    }
  
    if (pagination) {
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = pagination.innerHTML;
    }
  
    if (noDataText) {
      const customHTML = `
             <tr><td colspan='7' class='text-center'>
                  <div class="card py-3">    
                       <div class="card-body text-center py-10 my-5">
                                    該当のデータはありません。
                       </div>
                  </div>
                </td></tr>
                `;
      $('#nodata').html(customHTML);
      // this.resultsTableTarget.innerHTML=noDataText.innerHTML;
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = "";
      
    }
  }

  paginate(event) {
    event.preventDefault();
    const url = event.target.href;

    fetch(url, {
      headers: {
        Accept: "text/html", 
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch paginated results");
        }
        return response.text();
      })
      .then((html) => {
        this.parseAndUpdateResults(html); 
      })
      .catch((error) => console.error("Error fetching paginated results:", error));
  }

}
