import { Controller } from "@hotwired/stimulus"
import { Japanese } from "flatpickr/dist/l10n/ja"

// Connects to data-controller="coaches"
export default class extends Controller {
  static targets = ['searchBox', 'resultsTable'];
  connect() {
    console.log("hello coach");
    this.date_picker();
  }

  date_picker() {
    // Published Date From
    var dob = flatpickr(".dob", {
      // enableTime: true,
      maxDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: "true",
      allowInvalidPreload: true,
      altInput: true,
      altFormat: "Y年m月d日",
      onChange: function (selectedDates, dateStr, instance) {
        console.log(selectedDates);
      },
    });
  }  

  
search() {
  const coachsearch = this.searchBoxTarget.value;
  // Use filterResults to perform the dynamic filtering
  this.filterResults(coachsearch);
}

filterResults(coachsearch) {
  const url = new URL(window.location.href);

  // Update the search query for name
  if (coachsearch) {
    url.searchParams.set("q[coach_search]", coachsearch); 
  } else {
    url.searchParams.delete("q[coach_search]"); 
  }

  // Fetch filtered results dynamically
  fetch(url)
    .then((response) => response.text())
    .then((html) => {
      this.updateResults(html);
    })
    .catch((error) => console.error("Error fetching filtered results:", error));
}
  
  // Update results dynamically
  updateResults(html) {
    console.log("Updating results...");
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    const tableContainer = doc.querySelector("tbody");
    const pagination = doc.querySelector("#page");
    const noDataText = doc.querySelector("#no-data-text");
  
    if (tableContainer) {
      this.resultsTableTarget.innerHTML = tableContainer.innerHTML;
    }
  
    if (pagination) {
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = pagination.innerHTML;
    }
  
    if (noDataText) {
      const customHTML = `
             <tr><td colspan='7' class='text-center'>
                  <div class="card py-3">    
                       <div class="card-body text-center py-10 my-5">
                                    該当のデータはありません。
                       </div>
                  </div>
                </td></tr>
                `;
      $('#nodata').html(customHTML);
      
      const paginationDiv = document.querySelector("#page");
      if (paginationDiv) paginationDiv.innerHTML = "";
      
    }
  }
  
 
}
