import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="ai-chat"
export default class extends Controller {
  static targets = ['aiFrame', 'aiBtn'];
  connect() {
    console.log('AI chat controller is connected');
  }

  toggleChat() {
    const btn = this.aiBtnTarget;
    const frame = this.aiFrameTarget;
    frame.classList.toggle('show');
    btn.classList.toggle('show');
  }
}
